import axios from "axios";
import { useState, useRef } from "react";
import { useForm } from "react-hook-form";
import CtaBtn from "./../components/CtaBtn";
import Select from "react-select";
import { useFormik } from "formik";
import * as Yup from "yup";
// React-select Component
const options = [
  // {  value: '', label: 'Your location'},
  { value: "United Kingdom", label: "United Kingdom" },
  { value: "France", label: "France" },
  { value: "Germany", label: "Germany" },
  { value: "Italy", label: "Italy" },
  { value: "Kuwait", label: "Kuwait" },
  { value: "Saudi Arabia", label: "Saudi Arabia" },
  { value: "Singapore", label: "Singapore" },
  { value: "South Africa", label: "South Africa" },
  { value: "Spain", label: "Spain" },
  { value: "Qatar", label: "Qatar" },
  { value: "UAE", label: "United Arab Emirates" },
  { value: "USA", label: "United States" },
];


// Validation Schema
const SignupSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  company_name: Yup.string().required("Required"),
  phone_number: Yup.string().required("Required"),
  location:Yup.object().shape({
    label: Yup.string().required("Is required"), 
    value: Yup.string().required("Is required")
  }),
  email_address: Yup.string().email("Invalid email").required("Required"),
  message: Yup.string(),
});

function ContactForm() {
  const API_URL = process.env.REACT_APP_API_URL;
  const UNAME = process.env.REACT_APP_API_USERNAME;
  const PASSWD = process.env.REACT_APP_API_PASSWORD;
  const [resStatus, setResStatus] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [res, setRes] = useState();
  const ref = useRef()
  // Formik
  const formik = useFormik({
    initialValues: {
      name: "",
      email_address: "",
      company_name: "",
      phone_number: "",
      message: "",
      location: {value: "", label: "Your location"},
    },
    validationSchema: SignupSchema,
    onSubmit: (values) => {
      let location = values.location.value

      setSubmitting(true);
      var config = {
        method: "post",
        url: API_URL,
        auth: {
          username: UNAME,
          password: PASSWD,
        },
        headers: {
          "Content-Type": "application/json",
        },
        data: {...values,location},
      };

      axios(config)
        .then(function (response) {
          if(response.status === 200 && response.data){
              if(response.data?.message){
                setRes(
                  "Your request has been submitted successfully. We'll reach out to you soon!"
                );
                } 
                else if (response.data?.errors) {
                  setRes(response.data.errors);
                }
                setSubmitting(false);
            }
          }
          )
        .catch(function (error) {
          setSubmitting(false);
          console.log(error);
        }).finally(function (){
          setSubmitting(false);
          formik.resetForm()
        })
    },
  });

  const customStyles = {
    control: (base, state) => {
      return {
        ...base,
        boxShadow: state.isFocused ? 0 : 0,
        borderColor: state.isFocused
          ? "#6F6CFF"
          : formik.errors.location
          ? "red"
          : "",
        "&:hover": {
          borderColor: state.isFocused ? "#6F6CFF" : "border-gray-200",
        },
      };
    },
    singleValue: (provided, sas) => {
      if (sas.children === "Your location") {
        return {
          ...provided,
          color: "rgba(0,0,0,.5)",
        };
      } else {
        return {
          ...provided,
          color: "black",
        };
      }
    },
  };

  return (
    <div className="w-full p-7 lg:p-10 pt-8 rounded-lg bg-white contact-form">
        <iframe src="https://forms.copper.com/j/rz33v5GBgPztVGArfBftTp?type=embed "  id="nmuNbAqU1aBXBG2Cp3wzTt" title="Embedded Copper Forms" width="100%" height="600" frameBorder="0" ></iframe>
    </div>
  );
}

export default ContactForm;
